// This is not the best solution, but getting the routes right was quite complicated.
export default function useSystemPages() {
  const { currentDesk } = useCurrentDesk()

  const systemPages = computed<SystemPage[]>(() => {
    return [
      {
        id: 2,
        title: 'Organisatie registereren',
        path: '/organizations/signup',
        identifier: 'organization-signup',
      },
      {
        id: 6,
        title: 'Activiteiten overzicht',
        path: `/${currentDesk?.value?.slug}/nieuwkomer/activiteiten`,
        identifier: 'activity-search',
      },
      { id: 8, title: 'Inloggen', path: '/auth/login-1', identifier: 'login' },
      { id: 9, title: 'Wachtwoord vergeten', path: '/auth/recover', identifier: 'password-recovery' },
      { id: 10, title: 'Vrijwilliger registereren', path: '/signup', identifier: 'volunteer-signup' },
    ]
  })

  const findSystemPageByTitle = (title: string) => {
    return systemPages.value.find(page => page.title == title)
  }

  const findSystemPageById = (id: id) => {
    return systemPages.value.find(page => page.id == id)
  }

  const findSystemPageByIdentifier = (identifier: string) => {
    return systemPages.value.find(page => page.identifier == identifier)
  }

  return {
    systemPages,
    findSystemPageByTitle,
    findSystemPageById,
    findSystemPageByIdentifier,
  }
}
